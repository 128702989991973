import * as React from 'react';
import logo from '../../../assets/images/logo.png';
import webstore from '../images/webstore.png';
import Footer from '../../footer/component';
import Slider from '../../ui/slider/component';
import slide1 from '../images/slides/1.png';
import slide2 from '../images/slides/2.png';
import slide3 from '../images/slides/3.png';
import slide4 from '../images/slides/4.png';
import slide6 from '../images/slides/6.png';
import slide7 from '../images/slides/7.png';

import '../styles/index.css';

const slides = [slide1, slide2, slide3, slide4, slide6, slide7];

const Landing: React.SFC = () => (
  <div className="landing">
    <section className="screen-1">
      <div className="screen-1-content">
        <img src={logo} className="logo" alt="logo" />
        <h1>
          Работайте во Вконтакте
          <br />
          ещё быстрее и эффективней
        </h1>
        <h2>
          Нужные инструменты будут
          <br />
          всегда под рукой
        </h2>

        <div className="screen-1-button">
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Установить расширение"
            href="https://chrome.google.com/webstore/detail/super-smm-vk-fb-inst-ok/lkhdjcjomgifjdihdiecifkigmdemjlh"
          >
            <button>
              <img src={webstore} className="webstore" alt="webstore" />
              <span>Установить расширение</span>
            </button>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://super-smm.com/confed.html"
            title="Политика конфиденциальности"
          >
            Политика конфиденциальности
          </a>
        </div>
      </div>
    </section>
    <section className="screen-2">
      <h2>Так выглядит управление после установки</h2>

      <div className="screen-2-slider">
        <Slider slides={slides} />
      </div>

      <h2>Какие функции есть в расширении?</h2>

      <div className="screen-2-row">
        <div className="screen-2-text">
          <h3>Работайте с постами</h3>
          <span>
            Публикуйте посты сразу на все страницы и группы в 1 клик. Среди опций есть возможность
            отложенных публикаций, сохранения и копирования постов. В настройках можно загружать
            свои файлы, назначать постам разные метки и отслеживать всё через большой календарь.
          </span>
        </div>
        <div className="screen-2-video">
          <div className="ssmm-video-wrap">
            <iframe
              title="Работайте с постами"
              src="https://www.youtube.com/embed/cSfJRfD4Nb0"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="screen-2-row">
        <div className="screen-2-text">
          <h3>Усиление группы</h3>
          <span>
            Добавьте чат-бота с элементами геймификации в свою группу за 2 минуты. Ещё никогда
            Чатбот не настраивался так быстро и легко. При создания бота у Вас будет возможность
            назначать кнопки, менять их цвет и создавать меню. Чатбот будет работать без вашего
            участия, отвечая на самые популярные вопросы Ваших клиентов.
          </span>
        </div>
        <div className="screen-2-video">
          <div className="ssmm-video-wrap">
            <iframe
              title="Усиление группы"
              src="https://www.youtube.com/embed/azNPCd8kOQo"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="screen-2-row">
        <div className="screen-2-text">
          <h3>Учёт и контроль при помощи CRM</h3>
          <span>
            Оцифруйте своих подписчиков и клиентов, чтобы автоматизировать часть работы и всегда
            помнить о предстоящих делах. Подключите свою команду, для еще более удобной работы.
            Тепрь Вы точно не потеряете ни одного клиента. Система за вас будет выполнять различные
            действия и напоминать Вам о важных клиентах вовремя.
          </span>
        </div>
        <div className="screen-2-video">
          <div className="ssmm-video-wrap">
            <iframe
              title="Учёт и контроль при помощи CRM"
              src="https://www.youtube.com/embed/j2CjH44zRBQ"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="screen-2-row">
        <div className="screen-2-text">
          <h3>Ускорьте общение</h3>
          <span>
            Через быстрые сообщения и действия, Вы сможете значительно повысить качество общения с
            клиентом. В диалогах появится ряд новых возможностей. Создание быстрых сообщений,
            воронок и быстрых действий. Вы сможете заранее подготовить отличные шаблоны ответов и не
            тратить на это время во время важных переписок.
          </span>
        </div>
        <div className="screen-2-video">
          <div className="ssmm-video-wrap">
            <iframe
              title="Ускорьте общение"
              src="https://www.youtube.com/embed/7TzjC2quZz4"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="screen-2-row">
        <div className="screen-2-text">
          <h3>Все аккаунты будут под рукой</h3>
          <span>
            Подключите свои дополнительные аккаунты и управляйте ими централизованно. Вам будут
            доступны практически все функции управления аккаунтом, без потребность открывать
            множество вкладок. Если на дополнительные аккаунты кто-то напишет, вы сразу об этом
            узнаете и сможете быстро ответить.
          </span>
        </div>
        <div className="screen-2-video">
          <div className="ssmm-video-wrap">
            <iframe
              title="Все аккаунты будут под рукой"
              src="https://www.youtube.com/embed/V9WyOzMoee4"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="screen-2-row">
        <div className="screen-2-text">
          <h3>Публикация историй из Instagram во Вконтакте</h3>
          <span>
            Больше не придётся руками копировать истории. Функция автоматически проверит ваши
            истории в Инстаграме и опубликует их во Вконтакте.
          </span>
        </div>
        <div className="screen-2-video">
          <div className="ssmm-video-wrap">
            <iframe
              title="Публикация историй из Instagram во Вконтакте"
              src="https://www.youtube.com/embed/ZQwLE_IzTt4"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
);

export default React.memo(Landing);
