import React from 'react';
import vk from '../../../assets/images/soc-icons/vk.svg';

import '../styles/index.css';

const Footer: React.SFC = () => (
  <footer>
    <div className="footer-soc-links">
      <span>Социальные&nbsp;сети:</span>
      <div className="footer-soc-links-container">
        <a
          target="_blank"
          href="https://vk.com/vsemsmm1"
          title="Техническая поддержка сервиса"
          rel="noopener noreferrer"
        >
          <img src={vk} title="vk" alt="vk" className="ssmm-vk-logo" />
        </a>
      </div>
    </div>
    <div className="footer-center">
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://super-smm.com/confed.html"
          title="Политика конфиденциальности"
        >
          <span>Политика&nbsp;конфиденциальности</span>
        </a>{' '}
        |{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://super-smm.com/offer.html"
          title="Публичная оферта"
        >
          <span>Публичная&nbsp;оферта</span>
        </a>{' '}
        |{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://super-smm.com/forum"
          title="Техническая поддержка"
        >
          <span>Техническая&nbsp;поддержка</span>
        </a>
      </p>
      <p>Copyright © OOO “СУПЕР-СММ” 2020, All rights reserved</p>
    </div>
    <div className="footer-feedback-wrap">
      <div className="footer-feedback">
        <span>Обратная&nbsp;связь:</span>
        <a target="_blank" href="mailto:info@super-smm.com" rel="noopener noreferrer">
          info@super-smm.com
        </a>
      </div>
    </div>
  </footer>
);

export default React.memo(Footer);
