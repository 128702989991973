import * as React from 'react';
import Slick, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import '../styles/index.css';

interface Props {
  slides: string[];
}

const settings: Settings = {
  arrows: false,
  dots: false,
  infinite: true,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  variableWidth: false,
  autoplay: true,
  autoplaySpeed: 3000,
  adaptiveHeight: true,
  centerPadding: '0px',
};

const Slider: React.SFC<Props> = ({ slides }) => (
  <div className="slider-wrap">
    {slides && (
      <Slick {...settings}>
        {slides.map(slide => (
          <div key={slide} className="slider-slide">
            <img src={slide} alt="index" />
          </div>
        ))}
      </Slick>
    )}
  </div>
);

export default React.memo(Slider);
